import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import useSocket from "../../../omnia/hooks/use-socket";
import { produce } from "immer";
import _ from "lodash";
import {Chip, Grid, Link, Typography} from "@mui/material";
import moment from "moment/moment";
import {Link as RouterLink} from "react-router-dom";
import DataTable from "../../../omnia/components/elements/tables/data-table";

function SystemsTable(){

    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [distributions, setDistributions] = useState([]);
    const columns = [];
    const systemsRef = useRef();

    systemsRef.current = distributions;

    const {sendMessage} = useSocket('systems', data => {

        if (data['type'] === 'initial') {
            setDistributions(data['data']);
            setLoading(false);
        }

        if (data['type'] === 'system_removal') {
            setDistributions(prev => prev.filter(d => d.id !== data['id']));
        }

        if (data['type'] === 'system_update') {
            setDistributions(produce(systemsRef.current, draft => {
                let index = _.findIndex(systemsRef.current, {'id': data['data']['id']});
                if (index !== -1) {
                    draft[index] = data['data'];
                } else {
                    draft.push(data['data']);
                    // draft = draft.concat([details['details']]);
                }
            }));
        }
    });

    const handleDelete = (ids) => {
        setDistributions(prev => prev.filter(d => !ids.includes(d.id)))
        sendMessage({
            'type': 'remove',
            'ids': ids
        })
    }

    const getRegisteredSince = (data) => {

        return (
            <Typography variant="body2">
                {moment(getRegisteredSinceValue(data)).calendar()}
            </Typography>
        )

    }

    const getRegisteredSinceValue = (data) => {
        return new Date(data);
    }

    const getStatus = (data) => {
        if (!data.row.last_heartbeat) {
            return (
                <Chip
                    label={t("New Instance")}
                />
            );
        } else {
            if ((new Date() - new Date(data.row.last_heartbeat)) <= 60 * 1000 * 5) {
                return (
                    <Chip
                        label="Online"
                        color="primary"
                    />
                );
            } else {
                return (
                    <Chip
                        label="Offline"
                        color="error"
                    />
                );
            }
        }
    }

    const getStatusValue = (data) => {
        return data;
    }

    const getCustomer = (data) => {
        return (
            <Typography color="textPrimary">
                {/*<Link underline="hover" variant="h5" component={RouterLink} color="textPrimary" to={"#"} onClick={() => alert("In Arbeit")} >*/}
                <Link underline="hover" variant="h5" component={RouterLink} color="textPrimary"
                      to={"/systems/detail/" + data.row.id}>
                    <Typography variant="h5" style={{fontSize: 16}}>
                        {getCustomerValue(data, data?.row)}
                    </Typography>
                </Link>
            </Typography>
        )
    }

    const getCustomerValue = (data, row) => {
        return row?.company_name;
    }

    const getDomain = (data) => {
        return (
            <Typography variant="caption">
                {data?.row?.system_domain}
            </Typography>
        )
    }

    const getLastUpdate = (data) => {

        const hb = getLastUpdateValue(data);

        if (!hb)
            return (
                <Typography variant="body2">
                    {t("No Ping Yet")}
                </Typography>
            )

        return (
            <Typography variant="body2">
                {moment(hb).fromNow()}
            </Typography>
        )
    }

    const getLastUpdateValue = (data) => {
        if (data)
            return new Date(data);
        return null;
    }

    const getErrorCount = (data) => {
        if (data.row.error_count === 0)
            return <Chip label="Keine"/>
        return <Chip label={data.row.error_count} color="error"/>;
    }

    const getRequestCount = (data) => {
        if (data.row.request_count === 0)
            return <Chip label="Keine"/>
        return <Chip label={data.row.request_count} color="primary"/>;
    }

    const handleNewInstance = () => {
        alert('Feature coming soon')
    }

    columns.push({
        field: 'company_name',
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        sortable: true,
        headerName: 'Name',
        width: 200,
        renderCell: getCustomer,
        valueGetter: getCustomerValue
    });
    columns.push({
        field: 'status',
        type: 'singleSelect',
        valueOptions: ['Online', 'Offline'],
        headerAlign: 'left',
        align: 'left',
        sortable: true,
        headerName: 'Status',
        width: 150,
        renderCell: getStatus,
        valueGetter: getStatusValue
    });
    columns.push({
        field: 'last_heartbeat',
        type: 'dateTime',
        headerAlign: 'left',
        align: 'left',
        sortable: true,
        headerName: 'Letztes Update',
        width: 180,
        renderCell: getLastUpdate,
        valueGetter: getLastUpdateValue
    });
    columns.push({
        field: 'system_domain',
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        sortable: true,
        headerName: 'Domain',
        width: 200,
        renderCell: getDomain,
    });
    columns.push({
        field: 'error_count',
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        sortable: true,
        headerName: 'Fehler',
        width: 150,
        renderCell: getErrorCount,
    });
    columns.push({
        field: 'request_count',
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        sortable: true,
        headerName: 'Anfragen',
        width: 150,
        renderCell: getRequestCount
    });
    columns.push({
        field: 'registered_since',
        type: 'dateTime',
        headerAlign: 'left',
        align: 'left',
        sortable: true,
        headerName: 'Registriert seit',
        width: 180,
        renderCell: getRegisteredSince,
        valueGetter: getRegisteredSinceValue
    });

    return (
        <DataTable
            title="Systeme"
            subheader="Alle laufenden GROON Systeme"
            id="omnia-distributions-table"
            handleRemove={handleDelete}
            data={distributions}
            columns={columns}
            isLoading={loading}
        />
    )
}

export default SystemsTable;