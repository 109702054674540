import React, {useEffect, useState} from "react";
import useOmniaApi from "../../../omnia/hooks/use-omnia-api";
import {useTheme} from "@mui/system";
import { produce } from "immer";
import {CircularProgress, Grid} from "@mui/material";
import {Chart} from "src/omnia/components/elements/chart";
import moment from "moment";
import {useIsMobile} from "../../../omnia/hooks/use-is-mobile";

function ServerUtilization({server, height, ...rest}) {

    const {get, post} = useOmniaApi();
    const [loading, setLoading] = useState(true);
    const [seriesData, setSeriesData] = useState([]);
    const theme = useTheme();
    const { isMobile } = useIsMobile();

    const options = {
        colors: [theme.palette.primary.darkest, theme.palette.primary.main, theme.palette.primary.lightest],
        chart: {
            type: 'line',
            stacked: false,
            height: 350,
            animations: {
                enabled: false,
            },
            background: 'transparent',
            stroke: {
                curve: 'smooth'
            },
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                show: false
            }
        },
        sparkline: {
            enabled: isMobile
        },
        legend: {
            position: 'top'
        },
        theme: {
            mode: theme.palette.mode
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'round',
            colors: undefined,
            width: 2,
            dashArray: 0,
        },
        grid: {
            borderColor: theme.palette.divider,
            strokeDashArray: 2,
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        markers: {
            size: 0,
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val.toFixed(2) + ' ';
                },
            },
            min: 0,
            tickAmount: 3,
            max: 100,
        },
        xaxis: {
            type: 'datetime',
            tickAmount: 4,
            datetimeUTC: false,
        },
        tooltip: {
            shared: true,
            y: {
                formatter: function (val, w) {
                    let info;
                    if(w.seriesIndex === 0){
                        info = '(' + server.cpu_count + ' CPUs)'
                    }
                    if(w.seriesIndex === 1){
                        info = 'of ' + server.ram_total + ' GB'
                    }
                    if(w.seriesIndex === 2){
                        info = 'of' + server.storage_total + ' GB'
                    }
                    if (typeof (val) !== "undefined") {
                        return val.toFixed(0) + '% ' + info;
                    } else {
                        return ""
                    }
                }
            },
            x: {
                formatter: function (val) {
                    if (typeof (val) !== "undefined") {
                        return moment(new Date(val)).format('DD.MM.YYYY [um] HH:mm [Uhr]');
                    } else {
                        return ""
                    }
                }
            }
        }
    };

    useEffect(() => {
        setLoading(true);
        get('groon/servers/' + server.id + '/performance').then(data => {
            const series = produce(data, draft => {
                draft.sort((a, b) => {
                    let da = new Date(a.recorded_at).getTime();
                    let db = new Date(b.recorded_at).getTime();
                    return da > db ? 1 : -1;
                })
            })
            setSeriesData([
                {
                    name: 'CPU',
                    data: series.map(i => {
                        return {
                            x: new Date(i.recorded_at).getTime(),
                            y: parseFloat(i.cpu_load)
                        }
                    })
                }, {
                    name: 'Arbeitsspeicher',
                    data: series.map(i => {
                        return {
                            x: new Date(i.recorded_at).getTime(),
                            y: parseFloat(i.ram_load)
                        }
                    })
                }, {
                    name: 'Festplatte',
                    data: series.map(i => {
                        return {
                            x: new Date(i.recorded_at).getTime(),
                            y: parseFloat(i.storage_load)
                        }
                    })
                }
            ])
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    if(loading)
        return (
            <div style={{height: height, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress/>
            </div>
        )

    return (
        <div id="chart">
            <Chart
                options={options}
                series={seriesData}
                type="line"
                height={height}
            />
        </div>
    )

}

ServerUtilization.defaultProps = {
    height: 200
}

export default ServerUtilization;