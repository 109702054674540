import React, {
    forwardRef,
    useEffect, useCallback, useState
} from 'react';
import {Helmet} from 'react-helmet';
import {useLocation, useNavigate} from 'react-router';
import PropTypes from 'prop-types';
import track from 'src/omnia/utils/analytics';
import {useSelector} from "react-redux";
import {Box, Button, Container, Typography, useMediaQuery} from "@mui/material";
import {useSecurityCheck} from "../../hooks/use-security-check";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/system";
import {useAppMode} from "../../hooks/use-app-mode";
import {useSettings} from "../../hooks/use-settings";
import WobblingCircleBackground from "../elements/wobbling-circle-background";
import {useIsMobile} from "../../hooks/use-is-mobile";

const Page = forwardRef((
    {
        title,
        rights = [],
        withWobble = false,
        fitScreenHeight = false,
        children,
        maxWidth = 'lg',
        sx = {},
        ...rest
    }, ref) => {

    const location = useLocation();
    const theme = useTheme();
    const { t } = useTranslation();
    const {isBackend, isService, service} = useAppMode();
    const { isMobile, navBarMode } = useIsMobile();
    const settings = useSettings();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const {hasRights, isModuleInstalled} = useSecurityCheck();
    const [allowed, setAllowed] = useState(true);
    const moduleData = useSelector(state => state.account.modules);
    const mdUp = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const newMessages = useSelector(state => state.messages.unread);

    const topBarHeights = {
        // Other cases (e.g. vertical)
        plain: '64px',
        horizontal: '120px',
        vertical: isMobile ? '64px' : '64px',
        // When flying layout (otherwise 0px)
        topbar: '64px',
    }

    const serviceTopBarHeights = {
        // Other cases (e.g. vertical)
        plain: '64px',
        horizontal: '120px',
        vertical: '94px',

        // When flying layout
        mobile: '20px',
        topbar: '64px',
        flying: '0px',
        'flying-topbar': '75px',
    }

    const sendPageViewEvent = useCallback(() => {
        track.pageview({
            page_path: location.pathname
        });
    }, [location]);

    const handleHome = () => {
        navigate('/groon')
    };

    const activeLayout = (settings?.layout === 'flying') ? navBarMode : (settings?.layout || 'vertical');

    const deduction = isService
        ? serviceTopBarHeights?.[service?.layout || 'plain']
        : topBarHeights?.[activeLayout] || '0px';

    const minHeight = ('calc(100vh - ' + deduction + ')');

    useEffect(() => {
        sendPageViewEvent();
    }, [sendPageViewEvent]);

    useEffect(() => {
        // Check if the module is okay
        let moduleIsOkay = true;
        if(isBackend && pathname){

            // Check if
            const modules = Object.keys(moduleData);

            // Iterate over modules and do the below for all modules
            modules.forEach(module => {
                if(pathname.includes('/groon/' + module)){
                    if(!isModuleInstalled(module))
                        moduleIsOkay = false;
                }
            })
        }

        // Check if the permissions are okay
        const rightsAreOkay = hasRights(rights, "or");

        setAllowed(moduleIsOkay && rightsAreOkay);
    }, [rights, pathname, isBackend, moduleData])

    return (
        <>
            <Helmet>
                <title>{title} {newMessages > 0 ? '(' + newMessages + ')' : ''}</title>
            </Helmet>
            <WobblingCircleBackground show={withWobble} />
            <Box
                ref={ref}
                component="main"
                id="groon-page"
                sx={{
                    ...{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: deduction,

                        // Background image
                        backgroundImage: isService && theme?.config?.main_background?.view ? `url(${theme.config.main_background.view})` : 'none',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    },
                    ...(fitScreenHeight ? {height: '100%'} : {minHeight: minHeight}),
                    ...sx
                }}
                {...rest}
            >
                {allowed ? children : (
                    <Box
                        component="main"
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexGrow: 1,
                            py: '80px'
                        }}
                    >
                        <Container maxWidth={maxWidth}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mb: 6
                                }}
                            >
                                <Box
                                    alt="Not authorized"
                                    component="img"
                                    src={theme?.config?.error_403?.view || "/assets/errors/error-401.png"}
                                    sx={{
                                        height: 'auto',
                                        maxWidth: '100%',
                                        width: 200
                                    }}
                                />
                            </Box>
                            <Typography
                                align="center"
                                variant={mdUp ? 'h1' : 'h4'}
                            >
                                {t("pages.error_403_title")}
                            </Typography>
                            <Typography
                                align="center"
                                color="text.secondary"
                                sx={{ mt: 0.5 }}
                            >
                                {t("pages.error_403_subheader")}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: 6
                                }}
                            >
                                <Button onClick={handleHome} >
                                    {t("pages.back_home")}
                                </Button>
                            </Box>
                        </Container>
                    </Box>
                )}
            </Box>
        </>
    );
});

Page.displayName = "Page"

Page.propTypes = {
    children: PropTypes.node,
    rights: PropTypes.array,
    title: PropTypes.string,
    maxWidth: PropTypes.string,
    withWobble: PropTypes.bool
};

export default Page;
