import React, {useState} from "react";
import {
    CircularProgress,
    Grid,
} from "@mui/material";
import useSocket from "../../../omnia/hooks/use-socket";
import ServerCard from "./server-card";
import {useTranslation} from "react-i18next";

export default function ServersOverview() {
    const [servers, setServers] = useState(null);
    const {t} = useTranslation();

    const handleNewServer = () => {
        alert('Feature coming soon')
    }

    useSocket('systems', data => {
        if (data['type'] === 'servers') {
            setServers(data['data']);
        }
        if (data['type'] === 'server_update') {
            console.log(data["data"])
            setServers(prevServers => prevServers.map( server  =>
                server.id === data["data"].id ? { ...server, ...data["data"] } : server
            ));
        }
    });

    return (
        <Grid container spacing={3}>
            {servers ? servers.map(server => (
                <Grid item xs={12} sm={6} lg={4} key={server.id}>
                    <ServerCard
                        sx={{mb: 2, height: '100%'}}
                        minimal={true}
                        server={server}
                    />
                </Grid>
            )) : (
                <Grid item xs={12}>
                    <div style={{
                        height: 400,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}> <CircularProgress/>
                    </div>
                </Grid>
            )}
        </Grid>
    )

}