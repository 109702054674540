import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Grid,
    IconButton, Link,
    Menu, MenuItem,
    Stack,
    Typography
} from "@mui/material";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import ServerUtilization from "./server-utilization";
import {Link as RouterLink} from "react-router-dom";
import PropTypes from "prop-types";
import DotsHorizontal from '@untitled-ui/icons-react/build/esm/DotsHorizontal'

function getColor(percentage){
    const value = parseFloat(percentage);
    if(value < 75){
        return 'default';
    } else if(value < 85){
        return 'warning';
    } else {
        return 'error';
    }
}

function ServerCard({server, minimal, ...rest}) {

    const {t} = useTranslation();
    const [warning, setWarning] = useState(null);
    const [systems, setSystems] = useState([]);

    useEffect(() => {
        const cpu = server.cpu_warning_thrown;
        const offline = server.offline_warning_thrown;
        const ram = server.ram_warning_thrown;
        const storage = server.storage_warning_thrown;

        if (offline) {
            setWarning(minimal ? 'Offline' : 'Server is offline')
        } else {
            if (cpu) {
                setWarning(minimal ? 'CPU' : 'High CPU Load')
            } else {
                if (ram) {
                    setWarning(minimal ? 'RAM' : 'High RAM Load')
                } else {
                    if (storage) {
                        setWarning(minimal ? 'Storage' : 'High Storage Load')
                    } else {
                        setWarning(null);
                    }
                }
            }
        }

        setSystems([...new Set(server.workloads.map(w => w.system.company_name))]);

    }, [server]);

    const colors = {
        api: 'primary',
        task: 'primary',
        worker: 'secondary',
        redis: 'accent',
        db: 'accent'
    }

    const companyNames = server?.workloads?.map(item => item?.system?.company_name || null);
    const distinctCompanyNames = [...new Set(companyNames)];

    if(minimal)
        return (
            <Card {...rest}>
                <CardHeader
                    title={(
                        <Link
                            to={'/systems/server/' + server.id}
                            component={RouterLink}
                            variant='h6'
                        >
                            {server.name}
                        </Link>
                    )}
                    subheader={t('Ping') + ' ' + moment(server.last_update).fromNow()}
                    action={(
                        <>
                            {warning ? (
                                <Chip
                                    sx={{m: 0.5}}
                                    label={t(warning)}
                                    color={warning === 'Offline' ? 'error' : 'warning'}
                                />
                            ) : (
                                <Chip
                                    sx={{m: 0.5}}
                                    label={minimal ? t('Healthy') : t('System okay')}
                                    color='success'
                                />
                            )}
                        </>
                    )}
                />
                <CardContent sx={{pt: 0, pb: 0}}>
                    <>
                        {distinctCompanyNames.map(name => (
                            <Chip
                                sx={{mr: 1, mb: 1}}
                                key={name}
                                label={name}
                            />
                        ))}
                        {/*<Chip*/}
                        {/*    sx={{mr: 1, mb: 1}}*/}
                        {/*    color={getColor(server.cpu_load)}*/}
                        {/*    label={t('CPU') + ': ' + server.cpu_load.toFixed(1) + '%'}*/}
                        {/*/>*/}
                        {/*<Chip*/}
                        {/*    sx={{mr: 1, mb: 1}}*/}
                        {/*    color={getColor(server.ram_load)}*/}
                        {/*    label={t('RAM') + ': ' + server.ram_load.toFixed(1) + '%'}*/}
                        {/*/>*/}
                        {/*<Chip*/}
                        {/*    sx={{mr: 1, mb: 1}}*/}
                        {/*    color={getColor(server.storage_load)}*/}
                        {/*    label={t('Storage') + ': ' + server.storage_load.toFixed(1) + '%'}*/}
                        {/*/>*/}
                        {/*<Chip*/}
                        {/*    sx={{m: 0.5}}*/}
                        {/*    label={server.workloads.length + ' ' + t('Workloads')}*/}
                        {/*/>*/}
                    </>
                </CardContent>
            </Card>
        )

    return (
        <Card {...rest}>
            <CardHeader
                title={server.name}
                subheader={t('Ping') + ' ' + moment(server.last_update).fromNow()}
                action={
                    <>
                        {warning ? (
                            <Chip
                                label={t(warning)}
                                color={warning === 'Server is offline' ? 'error' : 'warning'}
                            />
                        ) : (
                            <Chip
                                label={t('System okay')}
                                color='success'
                            />
                        )}
                    </>
                }
            />
            <Divider/>
            <Box p={2}>
                <ServerUtilization
                    server={server}
                    height={350}
                />
            </Box>
            <Divider/>
            <Box p={2}>
                {/*
                    ('api', 'API'),
                    ('worker', 'Worker'),
                    ('task', 'Task'),
                    ('redis', 'Redis'),
                    ('db', 'Database')
                */}
                {systems.map((system, index) => (
                    <>
                        <Typography variant={'h6'} sx={{mb: 2, mt: index > 0 ? 2 : 0}}>
                            {system}
                        </Typography>
                        {server.workloads.filter(w => w.system.company_name === system).map(workload => (
                            <Chip
                                key={workload.id}
                                label={workload.name}
                                variant={workload.kind === 'api' ? 'contained' : 'outlined'}
                                color={colors[workload.kind]}
                                sx={{mr: 1, mb: 1}}
                            />
                        ))}
                    </>
                ))}
            </Box>
        </Card>
    )
}

ServerCard.defaultProps = {
    minimal: true
}

ServerCard.propTypes = {
    server: PropTypes.object.isRequired,
    minimal: PropTypes.bool
}

export default ServerCard;