// Global Omnia Application space-settings
import {defaultNavigationConfig} from "./omnia/components/layouts/dashboard/config";
import {Box, Stack} from "@mui/material";
import React from "react";
import SystemsTable from "./components/systems/systems/systems-table";
import SystemSettings from "./components/systems/settings/settings";
import ServersOverview from "./components/systems/cloud/servers";

export const APP_SETTING = {

    /**
     * Domain Setup
     *
     * Specify the core domain and protocol of the application
     */
    // domain: "localhost:8000",
    // protocol: "http",
    // socket_protocol: 'ws',
    domain: "www.groon.io",
    protocol: "https",
    socket_protocol: 'wss',

    /**
     * Sidebar
     *
     * Specify the sidebar items that shall be in the menu of this instance
     * Example:
     *      {
     *          title: 'Some Tool',
     *          path: '/custom-module/some-tool',
     *          icon: <FittingUntitledIcon />,
     *          items: [ ...children of the same type],
     *      },
     */
    navigation: [
        defaultNavigationConfig.home,
        defaultNavigationConfig.creativeStudio,
        {
            title: 'layout.company',
            icon: 'Building01',
            path: null,
            isModules: true,
            items: [
                defaultNavigationConfig.analytics,
                defaultNavigationConfig.resources,
                defaultNavigationConfig.connections,
                defaultNavigationConfig.projects,
                defaultNavigationConfig.marketing,
            ]
        },
    ],

    settings: [
        {
            label: 'Instanzen',
            subline: 'Verwaltung der GROON Inzanen',
            value: 'groon-systems',
            icon: 'Cloud03',
            component: (
                <Stack spacing={2}>
                    <Box>
                        <SystemSettings />
                    </Box>
                    <Box>
                        <SystemsTable />
                    </Box>
                    <Box>
                        <ServersOverview />
                    </Box>
                </Stack>
            ),
        }
    ],

    /**
     * Widgets
     *
     * Specify the widgets that shall be available in the dashboards of this instance
     * Example:
     *      {
     *          name: "Custom Widget",
     *          description: "This is a custom widget that does something",
     *          identifier: "UNIQUE-IDENTIFIER",
     *          module: "Custom Module",
     *          component: ReactComponentToUse
     *      },
     */
    widgets: [],

    /**
     * Services
     *
     * A list of all services (their identifiers!) that are available in this instance
     * Example:
     *     {
     *          name: "Service Name",
     *          identifier: "UNIQUE-IDENTIFIER"
     *     }
     */
    services: [
        {
            name: 'GROON Kundenportal',
            identifier: 'CUSTOMER_PORTAL',
            register_redirect: '/customer/login'
        },
    ],

}
